<template>
    <div
        class="text-collapse"
        :class="{ 'text-collapse--expanded': expand }"
        @click="handleClick"
    >
        <div
            ref="text"
            class="text-collapse__text"
            :style="{ '-webkit-line-clamp': expand ? null : maxLines }"
        >
            {{ text }}
        </div>

        <icon
            v-if="expandable"
            name="chevron-down"
            class="text-collapse__icon"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash-es';
import Icon from '@/components/ui/Icon';

export default {
    name: 'TextCollapse',

    components: { Icon },

    props: {
        text: {
            type: String,
            required: true,
        },

        maxLines: {
            type: Number,
            default: 3,
        },
    },

    data() {
        return {
            expand: false,
            expandable: false,
        };
    },

    mounted() {
        this.updateExpandable();

        this.resizeHandler = debounce(() => {
            const initialExpand = this.expand;

            if (this.expand) {
                this.expand = false;
            }
            this.$nextTick(() => {
                this.updateExpandable();
                this.expand = initialExpand;
            });
        }, 100);

        window.addEventListener('resize', this.resizeHandler);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        updateExpandable() {
            this.expandable = this.$refs.text.clientHeight < this.$refs.text.scrollHeight - 1;
        },

        handleClick() {
            if (this.expandable) {
                this.expand = !this.expand;
            }
        },
    },
};
</script>

<style scoped>
.text-collapse {
    @apply bg-gray-100 border border-gray-150;
    @apply bg-purple-200 border-purple-300;
    @apply flex text-md font-sofia text-gray-700 font-medium tracking-wider p-6 rounded-xl;
    box-shadow: 0px 3px 12px 0 rgb(0 0 0 / 12%);
}

.text-collapse__text {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-collapse--expanded .text-collapse__icon {
    @apply transform rotate-180;
}

.text-collapse__icon {
    @apply self-end ml-1 mb-1 min-w-3 w-3 h-2;
}
</style>
