<template>
    <Page
        v-if="rentableItem"
        :title="$t('ri.details.title')"
    >
        <template v-slot:default>
            <h2 class="title">{{ rentableItem.name }}</h2>

            <hr class="mb-8" />

            <div class="flex justify-between mb-4">
                <Rate
                    :value="rentableItem.timeslot.rate"
                    :unit="rentableItem.timeslot.unit"
                    :css="{ rateClass: 'flex items-center font-sofia text-xl text-purple-600', unitClass: 'lowercase' }"
                />
                <RentableItemAvailability
                    :available="rentableItem.itemsCounter.available"
                    :total="rentableItem.itemsCounter.total"
                />
            </div>

            <TextCollapse
                :text="rentableItem.description"
                class="mb-4"
            />

            <div class="categories mb-8 flex flex-wrap">
                <span
                    v-for="category in rentableItem.categories"
                    :key="category.id"
                    class="category"
                >
                    {{ category.name }}
                </span>
            </div>

            <h5 class="text-md font-ffdin font-bold text-gray-700 uppercase mb-4">
                {{ $t('ri.details.working_hours') }}
            </h5>

            <div
                v-for="period in Object.keys(groupedAvailabilitySchedule)"
                :key="period"
                class="bg-gray-150 p-4 mb-1 rounded-lg"
            >
                <h6 class="working-hours__label">{{ period }}</h6>
                <span
                    v-if="groupedAvailabilitySchedule[period].isClosed"
                    class="working-hours__value"
                >
                    {{ $t('ri.details.closed') }}
                </span>
                <ul v-else>
                    <li
                        v-for="({ open, close }, index) in groupedAvailabilitySchedule[period].workingHours"
                        :key="index"
                        class="working-hours__value"
                    >
                        {{ open }} – {{ close }}
                    </li>
                </ul>
            </div>
        </template>

        <template v-slot:footer>
            <div class="py-4 px-6 bg-white">
                <button
                    v-if="hasPermission('RP_RESERVATIONS_EDIT')"
                    type="button"
                    class="btn btn-primary w-full"
                    @click="$router.push({ name: 'reservations.create', params: { riId: $route.params.riId } })"
                >
                    {{ $t('ri.details.create_reservation') }}
                </button>
            </div>
        </template>
    </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import Page from '@/components/ui/Page';
import Rate from '@/components/ri/Rate';
import RentableItemAvailability from '@/components/ri/RentableItemAvailability';
import TextCollapse from '@/components/ui/TextCollapse';

export default {
    components: { Page, Rate, RentableItemAvailability, TextCollapse },

    mixins: [CommunityTimezoneMixin],

    data: () => {
        return {
            rentableItem: null,
        };
    },

    computed: {
        groupedAvailabilitySchedule() {
            return this.rentableItem.availabilitySchedule.reduce((acc, { description, open, close, isClosed }) => {
                const workingHours = { open: this.getFormattedTime(open), close: this.getFormattedTime(close) };

                if (Object.hasOwn(acc, description)) {
                    acc[description].workingHours.push(workingHours);
                } else {
                    acc[description] = {
                        isClosed,
                        workingHours: [workingHours],
                    };
                }

                return acc;
            }, {});
        },

        ...mapGetters({
            hasPermission: 'ri/hasPermission',
        }),
    },

    created() {
        this.fetchRentableItem();
    },

    methods: {
        getFormattedTime(time = '') {
            const timePieces = time.split(':');

            if (timePieces[0].length === 1) {
                return `0${timePieces[0]}:${timePieces[1]}`;
            }

            return time;
        },

        fetchRentableItem() {
            const { riId } = this.$route.params;

            this.$riDataProvider.getOne('rentableItems', { id: riId }).then(rentableItem => {
                this.rentableItem = rentableItem;
            });
        },
    },
};
</script>

<style scoped>
.title {
    @apply text-xl font-medium font-sofia text-gray-700 mb-2 lowercase;
}

.working-hours__label {
    @apply text-gray-500 text-xs font-ffdin font-medium lowercase tracking-wide;
}

.working-hours__value {
    @apply text-base font-sofia font-medium text-gray-700 mt-1;
}

.category {
    @apply bg-gray-150 py-1 px-3 text-gray-500 font-sofia text-xs rounded-lg tracking-wide mr-1 mb-1;
}

.category:last-child {
    @apply mr-0;
}
</style>
